<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        class="mb-md-0 mb-2"
      >
        <b-form-group
          label="Müşteri Ülke"
          label-for="i-center_type"
        >
          <v-select
            id="i-center_type"
            v-model="countries"
            :options="countries_options"
            label="title"
            :clearable="false"
          >
            <template #option="{ title, icon }" />
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="mb-md-0 mb-2"
      >
        <b-form-group
          label="Müşteri Adı"
          label-for="name"
        >
          <b-form-input
            v-model="company_name"
            placeholder="Müşteri Adı"
            name="name"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="mb-md-0 mb-2"
      >
        <b-form-group
          label="Müşteri Vergi No"
          label-for="company_tax_no"
        >
          <b-form-input
            v-model="company_tax_no"
            placeholder="Müşteri Vergi No"
            name="company_tax_no"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="mb-md-0 mb-2"
      >
        <b-form-group
          label="Müşteri Adres"
          label-for="company_address"
        >
          <b-form-input
            v-model="company_address"
            placeholder="Müşteri Adres"
            name="company_address"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="mb-md-0 mb-2"
      >
        <b-form-group
          label="Yetkili Adı"
          label-for="company_authorized_person_name"
        >
          <b-form-input
            v-model="company_authorized_person_name"
            placeholder="Yetkili Adı"
            name="company_authorized_person_name"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="mb-md-0 mb-2"
      >
        <b-form-group
          label="Yetkili E-posta"
          label-for="company_authorized_person_email"
        >
          <b-form-input
            v-model="company_authorized_person_email"
            placeholder="Yetkili E-posta"
            name="company_authorized_person_email"
          />
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="mb-md-0 mb-2"
      >
        <b-form-group
          label="Yetkili Telefon No"
          label-for="phone_no"
        >
          <vue-tel-input
            v-model="company_authorized_person_phone_number"
            mode="international"
            :input-options="custom_inputOptions"
            default-country="TR"
          >
            <template v-slot:arrow-icon>
              <span>▼</span>
            </template>
          </vue-tel-input>

        </b-form-group>
      </b-col>
    </b-row>
    <hr>
    <b-row class="justify-content-end">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="flat-success"
        class="mr-1"
        @click="record_update_button"
      >
        <feather-icon
          icon="SaveIcon"
          class="mr-1"
        />
        <span class="align-middle">Güncelle</span>
      </b-button>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
  BModal,
  BCardHeader,
  BCardBody,
  BLink,
  BFormInput,
  BTable,
  BBadge,
  BCardText,
  BFormGroup,
  BDropdown,
  BFormCheckbox,
  BDropdownItem,
  BPagination, VBModal, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import 'vue-tel-input/dist/vue-tel-input.css'
import { VueTelInput } from 'vue-tel-input'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import router from '@/router'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BAvatar,
    BModal,
    BCardHeader,
    BCardBody,
    BLink,
    BFormInput,
    BTable,
    BFormCheckbox,
    BBadge,
    BCardText,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    VueTelInput,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    customer_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      company_name: null,
      company_address: null,
      company_tax_no: null,
      company_authorized_person_name: null,
      company_authorized_person_phone_number: '',
      company_authorized_person_email: null,
      custom_inputOptions: {
        placeholder: 'Telefon No',
      },
      countries: { title: 'Müşteri Ülke', value: null },
      countries_options: [],
    }
  },
  computed: {
  },
  watch: {
  },
  created() {
    this.getCountriesData()
    useJwt.getCustomerDetail(this.customer_id)
      .then(response => {
        this.company_name = response.data.company_name
        this.company_address = response.data.company_address
        this.company_tax_no = response.data.company_tax_no
        this.company_authorized_person_name = response.data.company_authorized_person_name
        this.company_authorized_person_phone_number = response.data.company_authorized_person_phone_number
        this.company_authorized_person_email = response.data.company_authorized_person_email
        this.countries = { title: response.data.country.country_name, value: response.data.country.country_id }
      })
      .catch(error => {
        this.userData = undefined
        console.log(error.response)
      })
  },
  setup() {

  },
  methods: {
    getCountriesData() {
      useJwt.getCountries({
        page: 1,
        page_size: 50000,
      })
        .then(response => {
          response.data.results.forEach(data => {
            this.countries_options.push({
              title: data.country_name,
              value: data.country_id,
            })
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    record_update_button() {
      const formData = new FormData()
      formData.append('country', this.countries.value)
      formData.append('company_name', this.company_name)
      formData.append('company_address', this.company_address)
      formData.append('company_tax_no', this.company_tax_no)
      formData.append('company_authorized_person_name', this.company_authorized_person_name)
      formData.append('company_authorized_person_phone_number', this.company_authorized_person_phone_number)
      formData.append('company_authorized_person_email', this.company_authorized_person_email)
      useJwt.updateCustomer(this.customer_id, formData)
        .then(response => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Güncellendi',
              icon: 'CheckCircleIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
          this.$router.go(0)
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
