<template>
  <div>
    <!--    Ekle Modal-->
    <b-modal
      id="new_record_modal_customer"
      ref="new_record_modal_customer"
      centered

      no-close-on-backdrop
      content-class="shadow"
      title="Yeni Kayıt Ekle"
      footer-class="d-none"
      size="md"
      @hide="close_new_record_modal_customer"
    >
      <div>
        <b-row>
          <b-col
              cols="12"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Müşteri Ülke"
                label-for="i-center_type"
            >
              <v-select
                  id="i-center_type"
                  v-model="countries"
                  :options="countries_options"
                  label="title"
                  :clearable="false"
              >
                <template #option="{ title, icon }" />
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Müşteri Adı"
              label-for="name"
            >
              <b-form-input
                v-model="company_name"
                placeholder="Müşteri Adı"
                name="name"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Müşteri Vergi No"
                label-for="company_tax_no"
            >
              <b-form-input
                  v-model="company_tax_no"
                  placeholder="Müşteri Vergi No"
                  name="company_tax_no"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              class="mb-md-0 mb-2"
          >
            <b-form-group
                label="Müşteri Adres"
                label-for="company_address"
            >
              <b-form-input
                  v-model="company_address"
                  placeholder="Müşteri Adres"
                  name="company_address"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Yetkili Adı"
              label-for="company_authorized_person_name"
            >
              <b-form-input
                v-model="company_authorized_person_name"
                placeholder="Yetkili Adı"
                name="company_authorized_person_name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Yetkili E-posta"
              label-for="company_authorized_person_email"
            >
              <b-form-input
                v-model="company_authorized_person_email"
                placeholder="Yetkili E-posta"
                name="company_authorized_person_email"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              label="Yetkili Telefon No"
              label-for="phone_no"
            >
              <vue-tel-input
                v-model="company_authorized_person_phone_number"
                mode="international"
                :input-options="custom_inputOptions"
                default-country="TR"
              >
                <template v-slot:arrow-icon>
                  <span>▼</span>
                </template>
              </vue-tel-input>

            </b-form-group>
          </b-col>
        </b-row>
        <hr>
        <b-row class="justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1" size="sm"
            @click="new_record_add_button"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-1"
            />
            <span class="align-middle">Ekle</span>
          </b-button>
        </b-row>
      </div>

    </b-modal>
    <!--    Ekle Modal-->
    <!--    Customer Users Modal-->
    <b-modal
      id="users_modal"
      ref="users_modal"
      centered
      no-close-on-backdrop
      content-class="shadow"
      footer-class="d-none"
      size="xl"
      @hide="close_users_modal"
    >
      <customer-user-list-cart :customer_id="selected_row_id" />
    </b-modal>
    <!--    Customer Users Modal-->

    <!--    Customer Update Modal-->
    <b-modal
        id="customer_update"
        ref="customer_update"
        centered
        content-class="shadow"
        footer-class="d-none"
        size="md"
        title="Kayıt Düzenle"
    >
      <customer-update-cart :customer_id="selected_row_id" />
    </b-modal>
    <!--    Customer Update Modal-->

    <!-- Table Filter -->
    <!--    <b-card no-body>-->

    <!--      <b-card-body>-->
    <!--        <b-row>-->
    <!--          <b-col-->
    <!--            cols="12"-->
    <!--            md="2"-->
    <!--            class="mb-md-0 mb-2"-->
    <!--          >-->
    <!--            <b-form-group-->
    <!--              label="İl"-->
    <!--              label-for="i-il"-->
    <!--            >-->
    <!--              <v-select-->
    <!--                id="i-il"-->
    <!--                v-model="filter_il"-->
    <!--                :options="filter_il_options"-->
    <!--                label="title"-->
    <!--                :clearable="false"-->
    <!--              >-->
    <!--                <template #option="{ title, icon }" />-->
    <!--              </v-select>-->
    <!--            </b-form-group>-->

    <!--          </b-col>-->
    <!--          <b-col-->
    <!--            cols="12"-->
    <!--            md="2"-->
    <!--            class="mb-md-0 mb-2"-->
    <!--          >-->
    <!--            <b-form-group-->
    <!--              label="İlce"-->
    <!--              label-for="i-ilce"-->
    <!--            >-->
    <!--              <v-select-->
    <!--                id="i-ilce"-->
    <!--                v-model="filter_ilce"-->
    <!--                :options="filter_ilce_options"-->
    <!--                label="title"-->
    <!--                :clearable="false"-->
    <!--              >-->
    <!--                <template #option="{ title, icon }" />-->
    <!--              </v-select>-->
    <!--            </b-form-group>-->

    <!--          </b-col>-->
    <!--          <b-col-->
    <!--            cols="12"-->
    <!--            md="2"-->
    <!--            class="mb-md-0 mb-2"-->
    <!--          >-->
    <!--            <b-form-group-->
    <!--              label="Merkez Türü"-->
    <!--              label-for="i-merkez_turu"-->
    <!--            >-->
    <!--              <v-select-->
    <!--                id="i-merkez_turu"-->
    <!--                v-model="filter_center_type"-->
    <!--                :options="filter_center_type_options"-->
    <!--                label="title"-->
    <!--                :clearable="false"-->
    <!--              >-->
    <!--                <template #option="{ title, icon }" />-->
    <!--              </v-select>-->
    <!--            </b-form-group>-->
    <!--          </b-col>-->
    <!--          <b-col-->
    <!--            cols="12"-->
    <!--            md="2"-->
    <!--            class="mb-md-0 mb-2"-->
    <!--          >-->
    <!--            <b-form-group-->
    <!--              label="Merkez"-->
    <!--              label-for="i-il"-->
    <!--            >-->
    <!--              <v-select-->
    <!--                id="i-il"-->
    <!--                v-model="filter_il"-->
    <!--                :options="filter_il_options"-->
    <!--                label="title"-->
    <!--                :clearable="false"-->
    <!--              >-->
    <!--                <template #option="{ title, icon }" />-->
    <!--              </v-select>-->
    <!--            </b-form-group>-->

    <!--          </b-col>-->
    <!--          <b-col-->
    <!--            cols="12"-->
    <!--            md="2"-->
    <!--            class="mb-md-0 mb-2"-->
    <!--          >-->
    <!--            <b-form-group-->
    <!--              label="Yaşam Alanı"-->
    <!--              label-for="i-ilce"-->
    <!--            >-->
    <!--              <v-select-->
    <!--                id="i-ilce"-->
    <!--                v-model="filter_ilce"-->
    <!--                :options="filter_ilce_options"-->
    <!--                label="title"-->
    <!--                :clearable="false"-->
    <!--              >-->
    <!--                <template #option="{ title, icon }" />-->
    <!--              </v-select>-->
    <!--            </b-form-group>-->

    <!--          </b-col>-->
    <!--          <b-col-->
    <!--            cols="12"-->
    <!--            md="2"-->
    <!--            class="mb-md-0 mb-2"-->
    <!--          >-->
    <!--            <b-form-group-->
    <!--              label="Yaşam Alanı"-->
    <!--              label-for="i-ilce"-->
    <!--            >-->
    <!--              <v-select-->
    <!--                id="i-ilce"-->
    <!--                v-model="filter_ilce"-->
    <!--                :options="filter_ilce_options"-->
    <!--                label="title"-->
    <!--                :clearable="false"-->
    <!--              >-->
    <!--                <template #option="{ title, icon }" />-->
    <!--              </v-select>-->
    <!--            </b-form-group>-->

    <!--          </b-col>-->
    <!--        </b-row>-->
    <!--        <b-row>-->
    <!--          <b-col-->
    <!--            cols="12"-->
    <!--            md="2"-->
    <!--            class="mb-md-0 mb-2"-->
    <!--          >-->
    <!--            <b-form-group-->
    <!--              label="İl"-->
    <!--              label-for="i-il"-->
    <!--            >-->
    <!--              <v-select-->
    <!--                id="i-il"-->
    <!--                v-model="filter_il"-->
    <!--                :options="filter_il_options"-->
    <!--                label="title"-->
    <!--                :clearable="false"-->
    <!--              >-->
    <!--                <template #option="{ title, icon }" />-->
    <!--              </v-select>-->
    <!--            </b-form-group>-->

    <!--          </b-col>-->
    <!--          <b-col-->
    <!--            cols="12"-->
    <!--            md="2"-->
    <!--            class="mb-md-0 mb-2"-->
    <!--          >-->
    <!--            <b-form-group-->
    <!--              label="İlce"-->
    <!--              label-for="i-ilce"-->
    <!--            >-->
    <!--              <v-select-->
    <!--                id="i-ilce"-->
    <!--                v-model="filter_ilce"-->
    <!--                :options="filter_ilce_options"-->
    <!--                label="title"-->
    <!--                :clearable="false"-->
    <!--              >-->
    <!--                <template #option="{ title, icon }" />-->
    <!--              </v-select>-->
    <!--            </b-form-group>-->

    <!--          </b-col>-->
    <!--          <b-col-->
    <!--            cols="12"-->
    <!--            md="2"-->
    <!--            class="mb-md-0 mb-2"-->
    <!--          >-->
    <!--            <b-form-group-->
    <!--              label="Merkez Türü"-->
    <!--              label-for="i-merkez_turu"-->
    <!--            >-->
    <!--              <v-select-->
    <!--                id="i-merkez_turu"-->
    <!--                v-model="filter_center_type"-->
    <!--                :options="filter_center_type_options"-->
    <!--                label="title"-->
    <!--                :clearable="false"-->
    <!--              >-->
    <!--                <template #option="{ title, icon }" />-->
    <!--              </v-select>-->
    <!--            </b-form-group>-->
    <!--          </b-col>-->
    <!--          <b-col-->
    <!--            cols="12"-->
    <!--            md="2"-->
    <!--            class="mb-md-0 mb-2"-->
    <!--          >-->
    <!--            <b-form-group-->
    <!--              label="Merkez"-->
    <!--              label-for="i-il"-->
    <!--            >-->
    <!--              <v-select-->
    <!--                id="i-il"-->
    <!--                v-model="filter_il"-->
    <!--                :options="filter_il_options"-->
    <!--                label="title"-->
    <!--                :clearable="false"-->
    <!--              >-->
    <!--                <template #option="{ title, icon }" />-->
    <!--              </v-select>-->
    <!--            </b-form-group>-->

    <!--          </b-col>-->
    <!--          <b-col-->
    <!--            cols="12"-->
    <!--            md="2"-->
    <!--            class="mb-md-0 mb-2"-->
    <!--          >-->
    <!--            <b-form-group-->
    <!--              label="Yaşam Alanı"-->
    <!--              label-for="i-ilce"-->
    <!--            >-->
    <!--              <v-select-->
    <!--                id="i-ilce"-->
    <!--                v-model="filter_ilce"-->
    <!--                :options="filter_ilce_options"-->
    <!--                label="title"-->
    <!--                :clearable="false"-->
    <!--              >-->
    <!--                <template #option="{ title, icon }" />-->
    <!--              </v-select>-->
    <!--            </b-form-group>-->

    <!--          </b-col>-->
    <!--        </b-row>-->
    <!--      </b-card-body>-->
    <!--    </b-card>-->
    <!-- Table Filter -->

    <!-- Table Container Card -->
    <b-card  no-body>

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Sayfada</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>kayıt göster</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Ara..."
              />
              <b-button
                v-b-modal.new_record_modal_customer
                variant="primary"
              >
                <span class="text-nowrap">Yeni Ekle</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>
        <b-table
            responsive
            :items="table_data"
            :fields="tableColumns"
            primary-key="id"
            class="text-nowrap "
            style="min-height: 550px"
            show-empty
            :sort-by.sync="sortBy"
            :sort-desc.sync="isSortDirDesc"
            empty-text="Herhangi bir kayıt bulunamadı."
        >
          <!-- Column: country -->
          <template #cell(country)="data">
            {{ data.item.country.name }}
          </template>

          <!-- Column: company_name -->
          <template #cell(company_name)="data">
            <b-link :to="{ name: 'customers-management', params: { id: data.item.id }}">
              {{ data.item.company_name }}
            </b-link>
          </template>

          <!-- Column: phone -->
          <template #cell(company_authorized_person_phone_number)="data">
            <a :href="`tel:${data.item.company_authorized_person_phone_number}`">
              <b-badge
                  pill
                  variant="light-primary"
              >
                <feather-icon icon="PhoneIcon" /> {{ data.item.company_authorized_person_phone_number }}
              </b-badge>
            </a>
          </template>

          <!-- Column: email -->
          <template #cell(company_authorized_person_email)="data">

            <a :href="`mailto:${data.item.company_authorized_person_email}`">
              <b-badge
                  pill
                  variant="light-secondary"
              >
                <feather-icon icon="MailIcon" /> {{ data.item.company_authorized_person_email }}
              </b-badge>
            </a>
          </template>

          <!-- Column: users -->
          <template #cell(total_users_count)="data">
            <b-badge
                pill
                variant="light-warning"
                @click="show_users_modal(data.item.id)"
            >
              <feather-icon icon="UsersIcon" /> {{ data.item.total_users_count }}
            </b-badge>
          </template>

          <!-- Column: centers -->
          <template #cell(total_centers_count)="data">
            <b-badge
                pill
                variant="light-info"
            >
              <feather-icon icon="MapPinIcon" /> {{ data.item.total_centers_count }}
            </b-badge>
          </template>

          <!-- Column: Actions -->
          <template #cell(id)="data">
            <div class="text-nowrap">
              <!-- Dropdown -->
              <b-dropdown
                  variant="link"
                  toggle-class="p-0"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
              >

                <template #button-content>
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="$router.push({ name: 'customers-management', params: { id: data.item.id }})">
                  <feather-icon icon="SlidersIcon" />
                  <span class="align-middle ml-50">Müşteri Yönetimi</span>
                </b-dropdown-item>
                <b-dropdown-item @click="rowUpdateModal(data.item.id, )">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Düzenle</span>
                </b-dropdown-item>
                <!--              <b-dropdown-item @click="rowDeleteModal(data.item.id)">-->
                <!--                <feather-icon icon="TrashIcon" />-->
                <!--                <span class="align-middle ml-50">Sil</span>-->
                <!--              </b-dropdown-item>-->
              </b-dropdown>
            </div>
          </template>
        </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
          <!--          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>-->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BModal, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BTooltip, BCardHeader, BCardBody, VBModal, BCardText, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useJwt from '@/auth/jwt/useJwt'
import flatPickr from 'vue-flatpickr-component'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import Multiselect from 'vue-multiselect'
import { VueTelInput } from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
import CustomerUserListCart from '@/views/custom_app/admin/components/customer/CustomerUserListCart'
import CustomerUpdateCart from "@/views/custom_app/admin/components/customer/CustomerUpdateCart"
import {ref} from "@vue/composition-api";
export default {
  components: {
    BModal,
    BCardHeader,
    BCardBody,
    BCard,
    BRow,
    BCol,
    BLink,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BCardText,
    BFormGroup,
    BDropdown,
    BDropdownItem,
    BPagination,
    flatPickr,
    DateRangePicker,
    vSelect,
    Multiselect,
    VueTelInput,
    CustomerUpdateCart,
    CustomerUserListCart,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selected_row_id: null,
      perPageOptions: [25, 50, 100, 500, 1000],
      sortBy: null,
      isSortDirDesc: null,
      searchQuery: null,
      roleOptions: [],
      table_data: null,
      tableColumns: [
        { key: 'company_name', label: 'Müşteri Adı', sortable: true },
        { key: 'country', label: 'Ülke', sortable: true },
        { key: 'company_authorized_person_name', label: 'Yetkili Adı', sortable: true },
        { key: 'company_authorized_person_phone_number', label: 'Yetkili Telefon' },
        { key: 'company_authorized_person_email', label: 'Yetkili E-posta', sortable: true },
        { key: 'total_users_count', label: 'Kullanıcı Sayısı', sortable: true },
        { key: 'total_centers_count', label: 'Merkez Sayısı', sortable: true },
        { key: 'id', label: '#' },
      ],
      currentPage: 1,
      totalRows: 0,
      perPage: 25,
      company_name: null,
      company_address: null,
      company_tax_no: null,
      company_authorized_person_name: null,
      company_authorized_person_phone_number: null,
      company_authorized_person_email: null,
      custom_inputOptions: {
        placeholder: 'Telefon No',
      },
      countries: { title: 'Müşteri Ülke', value: null },
      countries_options: [],
    }
  },
  watch: {
    currentPage: {handler() {this.getTableData()},},
    sortBy: {handler() {this.getTableData()},},
    isSortDirDesc: {handler() {this.getTableData()},},
    searchQuery: {
      handler() {
        this.getTableData()
      },
    },
  },
  created() {
    this.getTableData()
    this.getCountriesData()
  },
  methods: {
    getTableData() {
      useJwt.getCustomerList({
        // center_type: this.filter_center_type.value,
        page: this.currentPage,
        page_size: this.perPage,
        search: this.searchQuery,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
      })
        .then(response => {
          this.table_data = response.data.results
          this.totalRows = response.data.count
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    getCountriesData() {
      useJwt.getCountries({
        page: 1,
        page_size: 50000,
      })
        .then(response => {
          response.data.results.forEach(data => {
            this.countries_options.push({
              title: data.name,
              value: data.id,
            })
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    rowUpdateModal(row_id) {
      this.selected_row_id = row_id
      this.$refs.customer_update.show()
    },
    rowDeleteModal(row_id) {
      useJwt.detailSurvivor(row_id)
        .then(response => {
          this.selected_row_name = `${response.data.name} ${response.data.last_name}`
          this.selected_row_id = response.data.id
          this.$refs.delete_record_modal.show()
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    new_record_add_button() {
      const formData = new FormData()
      formData.append('country', this.countries.value)
      formData.append('company_name', this.company_name)
      formData.append('company_address', this.company_address)
      formData.append('company_tax_no', this.company_tax_no)
      formData.append('company_authorized_person_name', this.company_authorized_person_name)
      formData.append('company_authorized_person_phone_number', this.company_authorized_person_phone_number)
      formData.append('company_authorized_person_email', this.company_authorized_person_email)
      useJwt.createCustomer(formData)
        .then(response => {
          this.getTableData()
          this.close_new_record_modal_customer()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Eklendi',
              icon: 'SaveIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    record_edit_button() {
      const formData = new FormData()
      formData.append('name', this.edit_merkez_adi)
      formData.append('address', this.edit_merkez_adres)
      formData.append('center_type', this.edit_center_type.value)
      formData.append('il', this.edit_il.value)
      formData.append('ilce', this.edit_ilce.value)
      useJwt.updateSurvivor(this.selected_row_id, formData)
        .then(response => {
          this.getTableData()
          this.close_edit_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Güncellendi',
              icon: 'CheckCircleIcon',
              variant: 'success',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    record_delete_button() {
      useJwt.deleteSurvivor(this.selected_row_id)
        .then(response => {
          this.getTableData()
          this.close_delete_record_modal()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Kayıt Silindi',
              icon: 'CheckCircleIcon',
              variant: 'danger',
              // text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            },
          })
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    close_new_record_modal_customer() {
      this.$refs.new_record_modal_customer.hide()
      this.company_name = null
      this.company_address = null
      this.company_tax_no = null
      this.company_authorized_person_name = null
      this.company_authorized_person_phone_number = ''
      this.company_authorized_person_email = null
      this.countries = { title: 'Müşteri Ülke', value: null }

    },
    close_edit_record_modal() {
      this.$refs.update_record_modal.hide()
      this.edit_yas_araligi = null
      this.selected_row_id = null
    },
    close_delete_record_modal() {
      this.$refs.delete_record_modal.hide()
      this.selected_row_id = null
    },
    show_users_modal(row_id) {
      this.selected_row_id = row_id
      this.$refs.users_modal.show()
    },
    close_users_modal() {
      this.$refs.users_modal.hide()
    },
  },
  setup() {

  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
